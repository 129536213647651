import React, { useContext, useEffect, useRef } from "react";
import { ConfigContext } from "../../AppConfigProvider";
import { isDev } from "../../../config/env";
import { adUnit } from "./GoogleAdUnit.css";

interface Props {
    id: string;
    sizes: googletag.GeneralSize;
}

const GoogleAdSlot: React.FC<Props & { slotId: string }> = ({
    id,
    slotId,
    sizes,
}) => {
    const slotRef = useRef<googletag.Slot | null>(null);
    useEffect(() => {
        try {
            window.googletag = window.googletag || { cmd: [] };
            googletag.cmd.push(() => {
                slotRef.current = googletag.defineSlot(slotId, sizes, id);
                slotRef.current?.addService(googletag.pubads());
                googletag.display(id);
            });
        } catch (e) {
            console.error(`AdUnit ${id} init error`);
            console.error(e);
        }
        return () => {
            if (
                window.googletag &&
                !!window.googletag.destroySlots &&
                slotRef.current
            ) {
                googletag.destroySlots([slotRef.current]);
            }
        };
    }, [id, slotId]);
    return <div id={id} className={adUnit} />;
};

const GoogleAdUnit: React.FC<Props> = ({ id, sizes }) => {
    const { googleAdUnitRoot } = useContext(ConfigContext);
    if (!googleAdUnitRoot || isDev) return null;

    return (
        <GoogleAdSlot
            id={`ad-slot-${id}`}
            slotId={`${googleAdUnitRoot}/${id}`}
            sizes={sizes}
        />
    );
};

export default GoogleAdUnit;
