import { Element, ElementContent, Parent } from "hast";
import { Node } from "unist";

const processList = (list: ElementContent[]) => {
    for (const li of list) {
        if (li.type !== "element") continue;
        const childList = li.children.find(
            (i): i is Element => i.type === "element" && i.tagName === "ul",
        );
        if (!childList) continue;
        processList(childList.children);
        if (childList.children.length < 15) continue;
        const listItemLink = li.children.find(
            i => i.type === "element" && i.tagName === "a",
        );
        if (!listItemLink) continue;
        li.children = [
            {
                type: "element",
                tagName: "details",
                children: [
                    {
                        type: "element",
                        tagName: "summary",
                        children: [listItemLink],
                    },
                    ...li.children.filter(i => i !== listItemLink),
                ],
            },
        ];
        li.properties = {
            ...li.properties,
            className: "toc-collapsible-li",
        };
    }
};

export default function makeTocCollapsible() {
    return (tree: Node) => {
        if (!tree || !("children" in tree)) return;
        const toc = (tree as Parent).children.find(
            (i): i is Element =>
                i.type === "element" && i.properties?.className === "toc",
        );
        if (!toc || !toc.children) return;
        processList(toc.children);
    };
}
